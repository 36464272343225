import { Link } from "gatsby";
import React from "react";
import { useModal } from "react-morphing-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AtendePortariaLogo from "../assets/icons/AtendePortaria-09.svg";
import bannerImage from "../assets/images/banner.jpg";
import AtendePortariaBanner from "../assets/images/parallax-bg4.jpg";
import Button from "../components/Button";
import Card from "../components/Card";
import FuctionContent from "../components/FunctionContent";
import InformationContent from "../components/InformationContent";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TestModal from "../components/TestModal";

function IndexPage() {
  const { modalProps, getTriggerProps } = useModal({
    background: "#fafafa",
  });

  return (
    <Layout>
      <SEO
        title="Biver"
        keywords={[
          `Biver`,
          `CRM`,
          `Franquias`,
          `Vendedores`,
          `Vendas`,
          `Gestão`,
        ]}
      />
      <TestModal modalProps={modalProps} />

      <Carousel showStatus={false} showThumbs={false} autoPlay>
        <div
          className="content-container flex items-center mb-0"
          style={{ backgroundColor: "#fafafa" }}
        >
          <div className="md:absolute md:w-3/5 xl:w-1/2 mx-0 md:mx-3 lg:mx-1 xl:mx-10">
            <h1 className="font-bold">
              A melhor ferramenta para gerenciar suas vendas
            </h1>
            <h3 className="text-gray my-5 font-light">
              O Biver CRM torna seu processo de vendas mais prático e auxilia
              sua equipe a fechar mais negócios.
            </h3>
            <button className="btn my-3 mb-10" {...getTriggerProps()}>
              ENTRAR EM CONTATO
            </button>
          </div>
          <img
            alt=""
            className="hidden md:block ml-auto lg:w-11/12"
            src={bannerImage}
          />
        </div>
      </Carousel>

      <div className="background-white py-8">
        <h1 className="font-bold text-center mt-16 mx-5 md:m-32">
          Conheça as principais funções para sua rede de vendedores
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-6 mx-5 md:mx-20 lg:mx-24 xl:mx-0 md:my-8">
          <FuctionContent
            className="xl:col-start-2"
            title="Funil de vendas"
            text="Acelere suas vendas, tenha controle e visibilidade sobre seu processo comercial."
            image="filter-alt"
          />
          <FuctionContent
            title="Gestão de tarefas"
            text="Crie e direcione tarefas para sua equipe de forma centralizada, organizada e gerenciável."
            image="calendar-event"
          />
          <FuctionContent
            title="Gestão de propostas"
            text="Gerencie as propostas comerciais para cada cliente e imprima ou envie por e-mail."
            image="file"
          />
          <FuctionContent
            className="xl:col-start-2"
            title="Produtos e serviços"
            text="Cadastre produtos e serviços e vincule diretamente em suas propostas."
            image="wrench"
          />
          <FuctionContent
            title="Histórico de interação"
            text="Mantenha um histórico detalhado de todas as interações com seus clientes."
            image="conversation"
          />
          <FuctionContent
            title="Envio de e-mails"
            text="Envie e-mails diretamente do CRM e crie modelos padrões para cada tipo de e-mail."
            image="mail-send"
          />
          <FuctionContent
            className="xl:col-start-2"
            title="Relatórios"
            text="Acompanhe todas as informações de desempenho do seu negócio."
            image="bar-chart-alt-2"
          />
          <FuctionContent
            title="Agenda"
            text="Diário, semanal ou mensal, tenha uma visão ampla das atividades da sua equipe."
            image="calendar"
          />
          <FuctionContent
            title="Proposta comercial"
            text="Crie um modelo de proposta personalizada para sua empresa e otimize o tempo gasto."
            image="star"
          />
          <FuctionContent
            pro
            className="xl:col-start-2"
            title="Gestão de franquias"
            text="Gerencie sua rede de franquias e acompanhe o desempenho de cada uma."
            image="share-alt"
          />
          <FuctionContent
            pro
            title="Registro de chamados"
            text="Centralize e padronize a abertura de chamados de suas unidades franqueadas."
            image="buoy"
          />
          <FuctionContent
            pro
            title="Gestão de royalties"
            text="Configure e acompanhe o faturamento de cada unidade franqueada."
            image="diamond"
          />
          <FuctionContent
            pro
            className="xl:col-start-2"
            title="Envio de comunicados"
            text="Envie comunicados, notificações e avisos para toda a sua rede de franquias."
            image="paper-plane"
          />
          <FuctionContent
            pro
            title="Biblioteca de materiais"
            text="Disponibilize materiais de marketing, institucionais e manuais para toda sua rede."
            image="book"
          />
        </div>
      </div>

      <div className="hidden grid grid-cols-1 lg:grid-cols-2 gap-12 mx-5 my-24">
        <img src={AtendePortariaBanner} alt="" className="col-span-1" />
        <div>
          <h1 className="font-bold">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </h1>
          <h3 className="text-gray md:mr-16">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit, sed do eiusmod tempor.
          </h3>
          <h3 className="mt-12 font-bold">Denis Perdigão</h3>
          <p className="text-gray mb-3">CEO da Atende Portaria</p>
          <img src={AtendePortariaLogo} alt="" className="w-32" />
        </div>
      </div>

      <InformationContent
        className="px-5 md:px-24 xl:px-64 py-24 background-white"
        title="Com o Biver sua empresa começa a faturar mais"
        description="O Biver CRM é ideal para empresas que precisam aperfeiçoar seu processo de vendas e aumentar seu faturamento."
      />

      <div className="py-10">
        <h1 className="font-bold text-center py-12 mx-5">
          Cresça melhor com o plano certo
        </h1>
        <div className="flex grid md:grid-cols-2 xl:grid-cols-4 gap-4 mx-5 md:mx-16 lg:mx-40 xl:mx-0 md:py-12">
          <Card
            className="col-span-1 xl:col-start-2"
            icon="award"
            title="CRM"
            description="Ideal para empresas que desejam aumentar a faturamento de sua equipe de vendas."
          >
            <h3 className="mb-3 font-bold">Principais recursos: </h3>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Funil de vendas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de tarefas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de propostas</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Cadastro de produtos e serviços</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Histórico de interação</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Envio de e-mails</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Relatórios</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Agenda</p>
            </div>
            <div className="flex mb-5">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Proposta comercial</p>
            </div>
            <Link to="/planos">
              <Button>Saiba mais</Button>
            </Link>
          </Card>
          <Card
            className="col-span-1 xl:col-start-3"
            bestSeller
            icon="trophy"
            title="CRM + Gestão de franquias"
            description="Solução voltada para rede de franqueados, com objetivo de padronizar a gestão, gerenciando e acompanhando o desempenho da rede, provocando melhorias no processo de comunicação."
          >
            <h3 className="mb-3 font-bold">Todos os recursos do CRM mais:</h3>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de franquias</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Registro de chamados</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Envio de comunicados</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Biblioteca de materiais</p>
            </div>
            <div className="flex mb-5">
              <box-icon name="check-circle" type="solid" color="#00b389" />
              <p className="text-gray ml-3">Gestão de royalties</p>
            </div>
            <Link to="/planos">
              <Button>Saiba mais</Button>
            </Link>
          </Card>
        </div>
      </div>

      <div className="hidden text-center py-32 px-5 md:px-24 lg:px-64 background-white">
        <h2 className="font-bold mb-5">
          {`"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit."`}
        </h2>
        <box-icon
          name="circle"
          type="solid"
          color="#8A94A6"
          size="cssSize"
          style={{ width: "5rem" }}
        />
        <h3 className="font-bold my-3">Denis Perdigão</h3>
        <p className="text-gray">CEO da Atende Portaria</p>
      </div>

      <div className="text-center py-32 px-5 md:px-24 lg:px-64 background-white">
        <h2 className="font-bold mb-5">
          {`"Precisávamos de uma solução que apoiasse a evolução da nossa operação de franquias, encontramos esse apoio no sistema de gestão Biver CRM. A parceria firmada com o fornecedor da solução e o uso massivo da ferramenta, otimizou nossa operação, melhorando o gerenciamento e nos permitiu criar um canal de comunicação com a rede simples e eficiente."`}
        </h2>
        <img src={AtendePortariaLogo} alt="" className="w-32 mx-auto" />
        <h3 className="font-bold my-3">José Júlio Pereira</h3>
        <p className="text-gray">CEO da Atende Portaria</p>
      </div>

      <InformationContent
        className="mx-5 md:mx-24 xl:mx-64 my-32"
        title="Junte-se as diversas empresas que confiam no Biver"
        description="Faça parte das empresas que estão crescendo com o CRM e veja suas vendas aumentarem rapidamente."
      />
    </Layout>
  );
}

export default IndexPage;
