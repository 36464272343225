typeof window !== "undefined" ? require("boxicons") : null;
import PropTypes from "prop-types";
import React from "react";

const FunctionContent = ({ className, image, title, pro, text }) => {
  return (
    <div className={`${className}`}>
      <box-icon name={image} color="#e57668" size="lg" className="my-3" />
      <div className="flex items-center">
        <h3 className="font-bold">{title}</h3>
        {pro && <div className="pro-icon text-center ml-2">FRA</div>}
      </div>
      <p className="text-gray">{text}</p>
    </div>
  );
};

FunctionContent.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  pro: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default FunctionContent;
